import { Link } from "react-router-dom";

export const Menu = () => (
  <div className="text-right py-8 px-10 font-medium">
    <MenuEntry path="/create">Neuer Link</MenuEntry>
    <MenuEntry path="/remove">Link löschen</MenuEntry>
    <MenuEntry
      onClick={() => {
        window.localStorage.removeItem("token");
        window.location.reload();
      }}
      path="/"
    >
      Abmelden
    </MenuEntry>
  </div>
);

const MenuEntry = (props) => (
  <Link
    {...props}
    to={props.path}
    className="inline-block mx-6 cursor-pointer text-gray-500 hover:text-current"
  >
    {props.children}
  </Link>
);
