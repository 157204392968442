import { useState } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import jwt from "jsonwebtoken";

import { LoginPage } from "pages/Login";
import { CreatePage } from "pages/Create";
import { RemovePage } from "pages/Remove";
import { ManifestPage } from "pages/Manifest";

const checkToken = () => {
  if (window.localStorage.getItem("token") == null) {
    return false;
  }
  try {
    if (
      jwt.decode(window.localStorage.getItem("token") || "").exp >
        Math.round(Date.now() / 1000)
    ) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

const App = () => {
  const [authenticated, setAuth] = useState(checkToken());
  console.log(!authenticated);

  return (
    <Router>
      {authenticated
        ? <Switch>
          <Route path="/create">
            <CreatePage />
          </Route>
          <Route path="/remove">
            <RemovePage />
          </Route>
          <Route path="/manifest">
            <ManifestPage />
          </Route>
          <Route>
            <Redirect to="/create" />
          </Route>
        </Switch>
        : <Switch>
          <Route path="/login/:token" component={({match})=>{window.localStorage.setItem("token", match.params.token);setAuth(checkToken());return(<Redirect to="/"/>)}}/>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/">
            <Redirect to="/login" />
          </Route>
        </Switch>}
    </Router>
  );
};

export default App;
