import { Menu } from "components/Menu";

export const CenterContainer = ({ children }) => (
  <div className="max-w-md mx-auto p-6 text-center h-screen flex flex-col">
    <div className="mt-auto"></div>
    <div className="">
      {children}
    </div>
    <div className="mt-auto"></div>
  </div>
);

export const AdvancedContainer = ({ children }) => (
  <>
    <Menu />
    <div className="max-w-xl mx-auto p-6" style={{ marginBottom: "2.25rem" }}>
      {children}
    </div>
    <div
      className="absolute bottom-0 w-full text-center p-2 text-sm text-gray-400"
    >
      © {new Date().getFullYear()} Alexander Schau |{" "}
      <a href="https://google.com" className="underline">Impressum</a>
    </div>
  </>
);
