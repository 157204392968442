import { useRef, useState } from "react";
import axios from "axios";

import { AdvancedContainer } from "components/Containers";
import { InfoMsg } from "components/Components";

export const RemovePage = () => {
  const [resp, setResp] = useState("");

  const shortInput = useRef();

  return (
    <AdvancedContainer>
      <div className="text-2xl font-bold mb-6">Link löschen</div>
      <p className="text-gray-600">
        Auf dieser Seite kannst du von dir erstellte Links löschen. Gebe dazu
        einfach das Kürzel des jeweiligen Links an.
      </p>
      <input
        ref={shortInput}
        className="input"
        placeholder="z.B. mein-kürzel oder ah3Nb"
      />
      <button
        onClick={() => {
          const short = shortInput.current.value;
          if (short != "") {
            axios.get(
              `https://sl-api.alxs.workers.dev/rm?short=${short}&token=${
                window.localStorage.getItem("token")
              }`,
            )
              .then((res) => {
                setResp(res.data.error == "" ? "ok" : "");
              });
          }
        }}
        className="button"
      >
        Link löschen
      </button>
      {resp == "ok"
        ? <InfoMsg>
          <b>Link wurde erfolgreich gelöscht.</b>
        </InfoMsg>
        : ""}
    </AdvancedContainer>
  );
};
