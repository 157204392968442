import axios from "axios";
import { InfoMsg } from "components/Components";
import { CenterContainer } from "components/Containers";
import { useRef, useState } from "react";

export const LoginPage = () => {
  const [sent, setSent]= useState(false)
  const emailInput = useRef();
  return (
    <>
      <CenterContainer>
        <h className="text-lg font-bold">Kurzlink Generator</h>
        <input ref={emailInput} className="input" placeholder="E-Mail Adresse" type="email" />
        <button
          className="button"
          onClick={() => {
            const email = emailInput.current.value
            if (email == "" || sent) {
              return
            }
            axios.get(`https://link.deno.dev/?email=${email}`)
            setSent(true)
          }}
        >
          Einloggen
        </button>
        {sent?
        <div className="text-left">
        <InfoMsg>
          Du erhälst in Kürze eine E-Mail von uns!
        </InfoMsg>
        </div>:""}
      </CenterContainer>
    </>
  );
};
