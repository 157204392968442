import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AdvancedContainer } from "components/Containers";
import { InfoMsg } from "components/Components";

export const CreatePage = () => {
  const [resp, setResp] = useState("");

  const urlInput = useRef();
  const shortInput = useRef();

  return (
    <AdvancedContainer>
      <div className="text-2xl font-bold mb-6">Neuer Link</div>
      <p className="text-gray-600">
        Auf dieser Seite kannst du neue Link erstellen. Bitte beachte unsere
        {" "}
        <Link to="/manifest" className="underline">Manifest</Link>.
      </p>
      <input
        ref={urlInput}
        className="input"
        placeholder="z.B. https://glauchau.dlrg.de/"
      />
      <label>dlrg.link/</label>
      <input
        ref={shortInput}
        className="input mt-0.5"
        placeholder="mein-kürzel (optional)"
      />
      <button
        onClick={() => {
          const url = urlInput.current.value;
          const short = shortInput.current.value;

          if (url != "") {
            axios.get(
              `https://sl-api.alxs.workers.dev/new?url=${url}&short=${short}&token=${
                window.localStorage.getItem("token")
              }`,
            )
              .then((res) => {
                setResp(
                  res.data.error == "" ? res.data.url : "",
                );
              });
          }
        }}
        className="button"
      >
        Link erstellen
      </button>
      {resp != ""
        ? <InfoMsg>
          <b>Kurzlink erfolgreich erstellt!</b>
          <p className="text-gray-500">{resp}</p>
        </InfoMsg>
        : ""}
    </AdvancedContainer>
  );
};
